/* Style for the new concept test layout */
#ul-survey-frame {
  width: 100%;
  display: flex;
  flex: 1;
}

.ul-question-side-layout-container {
  width: 100%;
  flex-direction: row;
}

.ul-question-center-layout-container {
  min-height: 100vh;
  flex-direction: row;
}

.ul-question-side-layout-customer-logo {
  max-height: 50px;
}

.ul-question-center-layout-customer-logo {
  max-height: 80px;
  max-width: 185px;
  margin: 2.25em auto;
}

.ul-question-side-layout-customer-logo-text {
  max-height: 50px;
  font-size: 1.75rem;
  text-align: center;
}

.ul-question-center-layout-customer-logo-text {
  margin: 2.25em auto;
  font-size: 1.75rem;
  text-align: center;
}

.survey-frame-header {
  justify-content: space-between;
}

button.study-toggle-button {
  background-color: transparent;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0 10px;
  border-radius: 3px;
}

.show-study-button {
  padding: 10px !important;
  position: absolute;
  right: 20px;
  top: 20px;
  color: white;
  z-index: 2;
}

.study-toggle-button:hover {
  cursor: pointer;
}

.ul-question-side-layout-container .survey-frame-header {
  padding: 20px 35px;
}

.ul-question-center-layout-container .link-study-alert {
  margin: -1rem 0 1rem;
}

.ul-websurvey-frame {
  flex: 3;
  max-width: 600px;
  margin: auto;
  background-color: white;
}

.ul-embed-frame {
  border: none;
  flex: 7;
  height: auto;
  transition: 0.5s filter linear;
  -webkit-transition: 0.5s all linear;
  -moz-transition: 0.5s all linear;
  -o-transition: 0.5s all linear;
}

.ul-filter-blur {
  pointer-events: none;
  filter: brightness(0.3);
  -moz-filter: brightness(0.3);
  -o-filter: brightness(0.3);
  -ms-filter: brightness(0.3);
  -webkit-filter: brightness(0.3);
}

/* study panel animation opening state */
.ul-websurvey-frame.survey-animation-enter {
  opacity: 0;
  flex: 0;
  transform: translateX(100%);
  overflow: visible;
}

.ul-websurvey-frame.survey-animation-enter-active {
  opacity: 1;
  flex: 3;
  transform: translate(0);
  transition: all 300ms cubic-bezier(0.44, 1.12, 1, 1);
}

.ul-websurvey-frame.survey-animation-enter-done {
  overflow: unset;
  display: block;
}

/* study panel animation closing state */
.ul-websurvey-frame.survey-animation-exit {
  opacity: 1;
  flex: 3;
  transform: translate(0);
  overflow: hidden;
}

.ul-websurvey-frame.survey-animation-exit-active {
  opacity: 0;
  flex: 0;
  transform: translateX(100%);
  transition: all 300ms cubic-bezier(0.44, 1.12, 1, 1);
}

.ul-websurvey-frame.survey-animation-exit-done {
  opacity: 0;
  overflow: unset;
  display: none;
}

/* study button animation opening state*/
button.study-button-animation-enter {
  opacity: 0;
  transform: translateX(20px);
}

button.study-button-animation-enter-active {
  opacity: 1;
  transform: translate(0);
  transition: all 150ms ease;
}

/* study button animation closing state */
button.study-button-animation-exit {
  opacity: 1;
  transform: translate(0);
}

button.study-button-animation-exit-active {
  opacity: 0;
  transform: translateX(20px);
  transition: all 150ms ease;
}

button.study-button-animation-exit-done {
  opacity: 0;
}

/* iframe animation opening state */
.ul-embed-frame.iframe-animation-enter {
  flex: 0;
  overflow: hidden;
}

.ul-embed-frame.iframe-animation-enter-active {
  flex: 7;
  transition: all 300ms cubic-bezier(0.44, 1.12, 1, 1);
}

.ul-embed-frame.iframe-animation-enter-done {
  flex: 7;
  overflow: unset;
}

/* iframe animation closing state */
.ul-embed-frame.iframe-animation-exit {
  opacity: 1;
  flex: 7;
  overflow: hidden;
}

.ul-embed-frame.iframe-animation-exit-active {
  flex: 0;
  opacity: 0;
  transition: all 300ms cubic-bezier(0.44, 1.12, 1, 1);
}

.ul-embed-frame.iframe-animation-exit-done {
  flex: 0;
  overflow: unset;
}

@media only screen and (max-width: 1030px) {
  .ul-websurvey-frame-mobile .survey-frame-header {
    justify-content: center;
    padding: unset;
  }

  .ul-question-center-layout-container .link-study-alert,
  .ul-question-side-layout-container .link-study-alert {
    margin: 0 20px;
  }

  .ul-question-side-layout-customer-logo-text,
  .ul-question-center-layout-customer-logo-text {
    margin: 1em auto;
  }

  .ul-question-center-layout-customer-logo,
  .ul-question-side-layout-customer-logo {
    max-height: 60px;
    max-width: 139px;
    margin: 1em auto;
  }

  .ul-embed-frame-mobile {
    height: 100%;
    width: 100%;
    position: absolute;
    flex: unset;
  }
  /* Need to reset position to hide the sidebar */
  .ul-websurvey-frame .ul-websurvey-sidebar {
    position: unset;
  }

  .ul-websurvey-frame-mobile {
    position: absolute;
    height: 100%;
    width: 100%;
    background: white;
  }

  .show-study-button-mobile {
    padding: 10px !important;
    right: unset;
    top: unset;
    bottom: 0;
    left: calc(50% - 60px); /* ideally we use transform here but the transform will impact the animation */
  }

  button.study-toggle-button-mobile {
    margin: 2em auto;
  }

  /* study button animation mobile states */
  button.study-button-animation-enter {
    transform: translate(0, 100%);
  }

  button.study-button-animation-enter-active {
    transform: translateY(0);
  }

  button.study-button-animation-exit-active {
    transform: translateY(100%);
  }

  /* study panel animation mobile opening state */
  .ul-websurvey-frame-mobile.survey-animation-enter {
    transform: translateY(100%);
    overflow: hidden;
  }

  .ul-websurvey-frame-mobile.survey-animation-enter-active {
    transform: translate(0);
    transition: all 300ms cubic-bezier(0.44, 1.12, 1, 1);
  }

  .ul-websurvey-frame-mobile.survey-animation-enter-done {
    overflow: unset;
    display: block;
  }

  /* study panel animation mobile closing state */
  .ul-websurvey-frame-mobile.survey-animation-exit {
    transform: translate(0);
    overflow: hidden;
  }

  .ul-websurvey-frame-mobile.survey-animation-exit-active {
    transform: translateY(100%);
    transition: all 300ms cubic-bezier(0.44, 1.12, 1, 1);
  }

  .ul-websurvey-frame-mobile.survey-animation-exit-done {
    display: none;
    overflow: unset;
  }
}

@media only screen and (max-width: 1030px) and (min-width: 650px) {
  .ul-question-side-layout-container .ul-websurvey-sidebar-mobile {
    position: unset; /* Need to reset position to have stripes be full-width */
    width: 600px;
  }
}
