#ul-survey-frame.survey-animation-enter {
  opacity: 0;
  transform: translateY(5px);
  display: flex;
}
#ul-survey-frame.survey-animation-enter-active {
  opacity: 1;
  transform: translate(0);
  animation-timing-function: cubic-bezier(0.08, 0.69, 0.37, 0.94);
  transition: opacity 250ms, transform 250ms;
}
#ul-survey-frame.survey-animation-exit {
  opacity: 1;
  transform: translate(0);
}
#ul-survey-frame.survey-animation-exit-active {
  opacity: 0;
  transform: translateY(5px);
  animation-timing-function: cubic-bezier(0.08, 0.69, 0.37, 0.94);
  transition: opacity 250ms, transform 250ms;
}
#ul-survey-frame.survey-animation-exit-done {
  display: none;
}

.survey-toggle:hover {
  background-color: #f0f0f1;
  transition: background-color 250ms cubic-bezier(0.455, 0.03, 0.515, 0.955);
}

#survey-taking-overlay {
  display: none;
  position: absolute;
  z-index: 1;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  background-color: white;
  opacity: 0.9;
}

.survey-taking-overlay-show {
  display: none !important;
}

.survey-toggle-text {
  font-weight: 600;
  font-size: 13px;
}

@media only screen and (max-width: 414px) {
  .mobile-active {
    width: 100% !important;
    left: 0;
    right: 0;
    margin: 0px !important;
    bottom: 55px !important;
    max-height: calc(100vh-55px);
    overflow: auto;
  }

  #survey-taking-overlay {
    display: block;
  }

  .survey-toggle:hover {
    background-color: transparent;
  }

  .survey-toggle {
    right: 15px;
  }
}
